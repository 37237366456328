<template>
  <!-- id : contents : (S) -->
  <v-app>
  <div id="contents">
    <div class="sub-visual-wrap st-community">
      <div class="inner-base-box">
        <h2 class="h2">{{ menuName }}</h2>
        <ol>
          <li style="background: none">홈</li>
          <li>{{ menuName }}</li>
          <li>{{ currentTabMenu.menuName }}</li>
        </ol>
      </div>
    </div>

    <section class="content-wrap">
      <div class="inner-base-box mb-pd-none" >
        <div class="tab-menu-box">
          <a href="javascript:;" class="btn-menu " :class="{open : this.$store.state.tapOpen === true}" @click="openTab()">탭열기버튼</a>
          <span class="t-txt">MENU</span>
        </div> 
        <div class="secPageTabBox " :class="{open : this.$store.state.tapOpen === true}">
          <div class="btn-go-back mb-only"><a href="#" @click.prevent="goback">이전페이지</a></div>
          <div class="sub-tab-menu-box st-community">
            <a href="javascript:;" v-for="(tab) in tabs" :class="{on:tab.url === '/group/schedule'}" @click="onClickTab(tab)" :key="tab.menuId">{{ tab.menuName }}</a>
          </div>
          <div class="sub-tab-mb-title">
            <a href="javascript:;" v-for="(tab) in tabs" :class="{on:tab.url === '/group/schedule'}" @click="onClickTabMobile()" :key="tab.menuId">{{ tab.menuName }} <i class="arrow"></i> </a>
          </div>
          <div class="sub-tab-list-box" :class="{on:this.tablistOpen === true}">
            <a href="javascript:;" v-for="(tab) in tabs" :class="{on:currentTabMenu.menuName==tab.menuName}" @click="onClickTab(tab)" :key="tab.menuId">{{ tab.menuName }}</a>
          </div>
        </div>
      </div>
      <div class="main-wrap">
        <div class="inner-base-box mb-pd-none">
          <div class="ev-calendar-wrap">
            <h2 class="h2Title">모임 등록</h2>
            <div class="formInfo">
              <v-form ref="formMeeting">
                <dl class="infoCont">
                  <dt class="formInfoTxt">
                    모임 분류 <i class="fa fa-asterisk" aria-hidden="true" />
                  </dt>
                  <dd class="formInfoArea">
                    <v-col class="d-flex">
                      <ul v-for="t in rootGroups" :key="t.groupId">
                        <v-checkbox
                          v-model="meeting.group.parentId"
                          :value="t.groupId"
                          :label="t.groupName"
                          hide-details
                          :rules="[rules.required]"
                          @change="meeting.groupId = null"
                          v-if="t.activated"
                        />
                      </ul>
                      <v-col class="mgr" style="max-width: 10px"> </v-col>
                      <v-col class="mgr" sm="2">
                        <v-select
                          :items="departmentItems"
                          item-value="groupId"
                          item-text="groupName"
                          v-model="meeting.groupId"
                          dense
                          clearable
                          outlined
                          flat
                          required
                          label="선택"
                          :rules="[rules.required]"
                          v-if="meeting.group.parentId === 1"
                        />
                        
                        <v-select
                          :items="clubItems"
                          item-value="groupId"
                          item-text="groupName"
                          v-model="meeting.groupId"
                          dense
                          clearable
                          outlined
                          flat
                          required
                          label="선택"
                          :rules="[rules.required]"
                          v-if="meeting.group.parentId === 2"
                        />
                      </v-col>
                    </v-col>
                  </dd>
                </dl>

                <dl class="infoCont">
                  <dt class="formInfoTxt">
                    제목 <i class="fa fa-asterisk" aria-hidden="true" />
                  </dt>
                  <dd class="formInfoArea">
                    <v-col class="mgr" sm="6">
                      <v-text-field
                        v-model="meeting.title"
                        :rules="[rules.required]"
                        label=""
                        dense
                        flat
                        clearable
                      />
                    </v-col>
                  </dd>
                </dl>
                <dl class="infoCont">
                  <dt class="formInfoTxt">장소</dt>
                  <dd class="formInfoArea">
                    <v-col class="mgr" sm="6">
                      <v-text-field
                        v-model="meeting.place"
                        label=""
                        dense
                        flat
                        clearable
                      />
                    </v-col>
                  </dd>
                </dl>
                <dl class="infoCont">
                  <dt class="formInfoTxt">주소</dt>
                  <dd class="formInfoAreaAddr">
                    <v-col>
                      <v-col class="mgr d-flex mb-meeting-info-addr">
                        <v-text-field
                          type="number"
                          v-model="meeting.zipCode"
                          label="우편번호"
                          @click="openZipCode"
                          v-on:keyup.13="openZipCode"
                          maxlength="6"
                          :rules="[
                            (value) =>
                              !value ||
                              (value.length >= 5 && value.length <= 6) ||
                              '우편번호 형식이 아닙니다.\n\r5~6자리 숫자로 입력해주세요.',
                          ]"
                        />
                        <v-text-field v-model="meeting.address" label="주소" />
                      </v-col>
                      <v-col class="mgr">
                        <v-text-field
                          v-model="meeting.addressDetail"
                          label="주소상세"
                        ></v-text-field>
                      </v-col>
                    </v-col>
                    <v-dialog
                      v-model="daumDialogVisible"
                      max-width="350px"
                      @click:outside="daumDialogVisible = false"
                      @keydown.esc="daumDialogVisible = false"
                    >
                      <daum-post @complete="completeZipCode" />
                    </v-dialog>
                  </dd>
                  <dt class="formInfoTxt">지도 상세</dt>
                  <dd class="formInfoArea">
                    <v-col
                      class="mgr"
                      sm="6"
                      v-if="meeting.lon && geos && geos.length > 1"
                    >
                      <v-select
                        :items="geos"
                        v-model="geo"
                        return-object
                        label="검색결과"
                        item-text="roadAddress"
                        dense
                        solo
                        flat
                        clearable
                        outlined
                        @change="setGeo"
                      />
                    </v-col>
                    <v-col class="mgr" v-if="meeting.lon">
                      <div v-viewer>
                      <img
                        :src="`https://naveropenapi.apigw.ntruss.com/map-static/v2/raster-cors?w=300&h=300&center=${meeting.lon},${meeting.lat}&markers=markers=type:d|size:mid|pos:${meeting.lon}%20${meeting.lat}&level=16&X-NCP-APIGW-API-KEY-ID=${$store.state.config.ext.naver.staticmap.id}`"
                      />
                      </div>
                    </v-col>
                    <v-col v-else-if="!meeting.lon && meeting.address" e
                      >좌표를 찾을 수 없습니다.</v-col
                    >
                  </dd>
                </dl>

                <dl class="infoCont">
                  <dt class="formInfoTxt">예상인원</dt>
                  <dd class="formInfoArea">
                    <v-col class="mgr" sm="2">
                      <v-text-field
                        v-model="meeting.estimateNumber"
                        label=""
                        dense
                        flat
                      />
                    </v-col>
                  </dd>
                </dl>
                <dl class="infoCont">
                  <dt class="formInfoTxt">
                    일정 <i class="fa fa-asterisk" aria-hidden="true" />
                  </dt>
                  <dd class="formInfoArea d-flex mb-meeting-info-date">
                    <span class="row-box">
                    <v-col class="mgr" >
                      <v-menu
                        v-model="startCal"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="100px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="meeting.startDt"
                            label="모임 시작일"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on" 
                            :rules="[rules.required]"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="meeting.startDt"
                          @input="setStartDt"
                          locale="ko"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col
                      class="mgr d-flex"
                      style="max-width: 100px;"
                    >
                      <v-img><v-icon>mdi-clock-outline</v-icon></v-img>
                      <vue-timepicker
                        manual-input
                        v-model="meeting.startTm"
                        :input-class="errStartTm ? 'invalid' : ''"
                        :minute-interval="10"
                        auto-scroll
                        @change="setStartTm"
                      />
                      <v-input hidden v-model="meeting.startTm"></v-input>
                    </v-col>
                    </span>
                    <span class="row-box">
                    <v-col
                      class="mgr align-center pc-only"
                      style="max-width: 20px; margin-top: 10px; margin-left:5rem"
                    >
                      <span class="line ">~</span>
                    </v-col>
                    <v-col class="mgr" >
                      <v-menu
                        v-model="endCal"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="meeting.endDt"
                            label="모임 종료일"
                            prepend-icon="mdi-calendar"
                            readonly
                            class="mb-date"
                            v-bind="attrs"
                            v-on="on"
                            :rules="[rules.required]"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="meeting.endDt"
                          @input="endCal = false"
                          locale="ko"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col
                      class="mgr d-flex"
                      style="max-width: 100px;"
                    >
                      <v-img><v-icon>mdi-clock-outline</v-icon></v-img>
                      <vue-timepicker
                        manual-input
                        v-model="meeting.endTm"
                        :input-class="errEndTm ? 'invalid' : ''"
                        :minute-interval="10"
                        auto-scroll
                      ></vue-timepicker>
                      <v-input hidden v-model="meeting.endTm"></v-input>
                    </v-col>
                    </span>
                  </dd>
                </dl>
                <dl class="infoCont">
                  <dt class="formInfoTxt">모임비</dt>
                  <dd class="formInfoArea">
                    <v-switch
                      v-model="hasEntryFee"
                      flat
                      color="primary"
                      :label="hasEntryFee ? '있음' : '없음'"
                      style="margin-top: 10px; padding-right: 10px"
                    ></v-switch>
                    <v-col class="mgr d-flex" sm="1">
                      <v-text-field
                        v-model="meeting.entryFee"
                        :rules="[
                          !hasEntryFee || rules.required,
                          !hasEntryFee || rules.qtZero,
                        ]"
                        label=""
                        dense
                        flat
                      />
                      <span style="padding-top: 20px">원</span>
                    </v-col>
                  </dd>
                </dl>

                <dl class="infoCont">
                  <dt class="formInfoTxt">상세내용</dt>
                  <dd class="formInfoArea">
                    <v-col class="mgr">
                      <editor-component
                        ref="meetingContents"
                        :val="meeting.meetingContents"
                        :uploaded="fileMeta"
                        @callback-upload="uploadImage"
                        label="상세 내용"
                      />
                    </v-col>
                  </dd>
                </dl>

                <!-- <dl class="infoCont">
                  <dt class="formInfoTxt">가족동반</dt>
                  <dd class="formInfoArea">
                    <v-col class="mgr">
                      <v-switch
                        v-model="meeting.family"
                        flat
                        color="primary"
                        :label="meeting.family ? '가능' : '불가'"
                      ></v-switch>
                    </v-col>
                  </dd>
                </dl> -->

                <dl class="infoCont">
                  <dt class="formInfoTxt">문의</dt>
                  <dd class="formInfoArea">
                    <v-col class="mgr" sm="6">
                      <v-text-field
                        v-model="meeting.askContact"
                        label=""
                        dense
                        flat
                        clearable
                      />
                    </v-col>
                  </dd>
                </dl>
                <dl class="infoCont">
                  <dt class="formInfoTxt">노출여부</dt>
                  <dd class="formInfoArea">
                    <v-col class="mgr d-flex" sm="6">
                      <v-switch
                          v-model="meeting.activated"
                          flat
                          color="primary"
                          style="padding-top: 15px"
                          :label="meeting.activated ? '노출' : '비노출'"
                      ></v-switch>
                      <v-spacer style="max-width: 15px"></v-spacer>
                      <v-col class="mgr" sm="2" v-if="meeting.activated">
                        <v-menu
                            v-model="postCal"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="meeting.postDt"
                                label="게시일"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                              v-model="meeting.postDt"
                              @input="postCal = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                    </v-col>
                  </dd>
                  <dt class="formInfoTxt">상태</dt>
                  <dd class="formInfoArea">
                    <v-col class="mgr" sm="6">
                      <v-select :items="stateItems"
                                v-model="meeting.state"
                                item-text="text"
                                item-value="value"
                                dense solo flat clearable
                                placeholder="상태를 선택해주세요."
                                outlined
                                :rules="[rules.required]"
                      />
                    </v-col>
                  </dd>

                </dl>
              </v-form>
            </div>
            <div class="btnStyleBx">
              <div class="btnCommon justify-center">
                <v-btn class="vBtnsc" color="#1991eb" dark @click="save">
                  <v-icon left>mdi mdi-content-save</v-icon>
                  저장
                </v-btn>
                <v-btn class="vBtnsc" color="#fafafa" @click="goList()">
                  <v-icon left>mdi mdi-format-list-bulleted</v-icon>
                  목록
                </v-btn>
                <v-btn class="vBtnsc" color="#fafafa" @click="goSchedule()">
                  <v-icon left>mdi mdi-calendar</v-icon>
                  일정
                </v-btn>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
  </v-app>
</template>

<script>
import VueTimepicker from "vue2-timepicker";
import "vue2-timepicker/dist/VueTimepicker.css";

import meetingApi from "@/api/MeetingApi";
import groupApi from "@/api/GroupApi";
import utilApi from "@/api/UtilApi";

import EditorComponent from "../component/EditorComponent";
import menuApi from "@/api/MenuApi";
import userApi from "@/api/UserApi";
// import _ from 'lodash';

export default {
  name: "MeetingForm",
  components: {
    "editor-component": EditorComponent,
    VueTimepicker,
  },
  props: {
    id: String,
    indexStatus:String,
    sComponent: {
      type: Boolean,
      default: false
    },
    status: String,
    isOpen:{
      type:Boolean,
      default:false
    }
  },
  data: () => ({
    daumDialogVisible: false,
    rules: {
      required: (value) => !!value || "필수 입력 항목입니다..",
      qtZero: (value) => (value && value > 0) || "필수 입력 항목입니다.",
    },
    meeting: {
      title: null,
      zipCode: null,
      address: null,
      meetingContents: null,
      entryFee: 0,
      activated: true,
      group: {
        parentId: 1,
      },
      groupId: null,
      lon: null,
      lat: null,
      state:"STATE_RECEIPT",
      startTm:{},
      endTm:{}
    },
    startCal: false,
    endCal: false,
    postCal: false,
    imageFileUrl: null,
    endPoint: process.env.VUE_APP_ATTACH_FILE_SERVER_URL,
    fileMeta: {},
    rootGroups: [],
    departmentItems: [],
    clubItems: [],
    geos: [],
    errStartTm: false,
    errEndTm: false,
    stateItems: [
      { text: "접수중", value: "STATE_RECEIPT" },
      { text: "접수대기", value: "STATE_READY" },
      { text: "마감", value: "STATE_END" },
      { text: "취소", value: "STATE_CANCEL" },
    ],

    tabIndex: 2,
    currentTabMenu: [],
    menuName:"",
    tabs: [],
    param: {
        searchKeyword: '',
        searchColumn: 'title',
        searchDate:[],
        searchState: null,
        page: 1,
        start: {},
        end: {}
      },
    tablistOpen:false,
  }),
  computed: {
    hasEntryFee: {
      get() {
        return this.meeting.entryFee !== "0";
      },
      set(value) {
        if (!value) {
          this.meeting.entryFee = "0";
        }
      },
    },
    geo() {
      return (
        this.geos.filter(
          (g) => g.x === this.meeting.lon && g.y === this.meeting.lat
        )?.[0] ?? null
      );
    },
  },
  methods: {

    onClickTab(tab){
      this.param.searchKeyword= ''
      this.param.searchColumn= 'title'
      this.param.fromDate= ''
      this.param.toDate= ''
      this.currentTabMenu = tab
      this.componentStatus = 'calendar'
      this.searchOpen = false

      let url = tab.url;
      if(tab.menuType === 'TYPE_BOARD') {
        url = `/board/${tab.boardId}`;
      }

      this.$router.push(url+`?upper=${this.parentMenu}&menu=${tab.menuId}`).catch(()=>{});
    },
    setGeo(item) {
      this.meeting.lon = item.x;
      this.meeting.lat = item.y;
    },
    openZipCode() {
      this.daumDialogVisible = true;
      // let _this = this;
      // // eslint-disable-next-line no-undef
      // new daum.Postcode({
      //   oncomplete: function(data) {
      //     // console.log(data);
      //
      //     _this.meeting.zipCode = data.zonecode;
      //     _this.meeting.address = data.address;
      //
      //
      //     utilApi.geo(data.address).then(res => {
      //       if(res.status === 'OK') {
      //         // console.log(res.addresses);
      //
      //         _this.meeting.lon = res.addresses[0]?.x;
      //         _this.meeting.lat = res.addresses[0]?.y;
      //         _this.geos = res.addresses;
      //         // console.log(_this.meeting);
      //       }
      //     });
      //     // console.log(_this.meeting);
      //
      //   }}).open();
    },
    completeZipCode(data) {
      this.meeting.zipCode = data.zonecode;
      this.meeting.address = data.address;

      utilApi.geo(data.address).then((res) => {
        if (res.status === "OK") {
          // console.log(res.addresses);

          this.meeting.lon = res.addresses[0]?.x;
          this.meeting.lat = res.addresses[0]?.y;
          this.geos = res.addresses;
          // console.log(_this.meeting);
        }
      });

      this.daumDialogVisible = false;
    },
    uploadImage(f) {
      meetingApi.uploadImage(f).then((res) => {
        this.fileMeta = res;
      });
    },
    goList() {
      this.$router.push({path : '/group/schedule/', 
      query:{upper:this.parentMenu,menu: this.currentTabMenu.menuId, indexStatus:'list'}});
    },
    goSchedule() {
      this.$router.push({path : '/group/schedule/', query:{upper:this.parentMenu,menu: this.currentTabMenu.menuId}});
    },
    setStartDt() {
      this.startCal = false;
      if (!this.meeting.endDt) {
        this.meeting.endDt = this.meeting.startDt;
        // this.meeting.endDt = this.$moment(this.meeting.startDt).add(1, 'd').format('YYYY-MM-DD');
      }
    },
    setStartTm(ed) {
      // console.log(this.meeting.startTm)
      // console.log("Object.values(this.meeting.startTm)")
      // console.log(Object.values(this.meeting.startTm))
      // console.log(Object.values(this.meeting.startTm).length)
      // console.log("H포함?")
      // console.log(Object.values(this.meeting.startTm).includes("H"))
      // console.log(this.meeting.startTm == '')
      
      this.meeting.endTm = `${
        parseInt(ed.data.HH, 10) < 23
          ? String(parseInt(ed.data.HH, 10) + 1).padStart(2, "0")
          : ed.data.HH
      }:${!ed.data.mm ? "mm" : ed.data.mm}`;
    },
    async save() {
      // if(!this.meeting.startTm) {
      //   this.errStartTm = true;
      // }
      // if(!this.meeting.endTm) {
        //   this.errEndTm = true;
      // }

      if (!(await this.$refs.formMeeting.validate())) return;
      if(Object.values(this.meeting.startTm).includes("H") || Object.values(this.meeting.endTm).includes("H") ||
         Object.values(this.meeting.startTm).includes("m") || Object.values(this.meeting.endTm).includes("m") ||
         Object.values(this.meeting.startTm).length == 0 || Object.values(this.meeting.endTm).length == 0){
       alert("모임시간을 정확히 선택해주세요"); return;
      }
      // if(this.meeting.startTm.mm == '' || this.meeting.endTm.mm == ''){
      //   alert("모임시간을 정확히 선택해주세요"); return;
      // }
      if (!this.meeting.groupId) {
        this.meeting.groupId = this.meeting.group.parentId;
      }

      this.meeting.meetingContents = this.$refs.meetingContents.getValue();

      if (!this.meeting.activated) {
        this.meeting.postDt = null;
      }

      if (this.meeting.startTm && typeof this.meeting.startTm === "object") {
          this.meeting.startTm =
          this.meeting.startTm.HH + ":" + this.meeting.startTm.mm + ":00";
          
      }
      if (this.meeting.endTm && typeof this.meeting.endTm === "object") {
        this.meeting.endTm =
          this.meeting.endTm.HH + ":" + this.meeting.endTm.mm + ":00";
      }
            meetingApi.save(this.meeting, this.files).then((data) => {
          if (data) {
            if (this.meeting.meetingId) {
              alert("수정되었습니다.");
            } else {
              alert("등록되었습니다.");
            }
            this.goList();
          } else {
            alert("Error");
          }
        });
    },
    /*탭 오픈*/
    openTab(){
      this.$store.commit('setTapOpen')
    },
    onClickTabMobile(){
      if(this.tablistOpen){
        this.tablistOpen = false;
      }else{
        this.tablistOpen = true;
      }
    },
    goback(){
      this.$router.go(-1);
    },
    logout() {
      this.$store.dispatch('user/logout');
      window.location.href='/'
    },
  },
  created() {
    if (this.$route.params.meetingId) {
      meetingApi.get(this.$route.params.meetingId).then((data) => {
        this.meeting = data;
        if (!this.meeting.group.parentId) {
          this.meeting.group.parentId = this.meeting.groupId;
        }
      });
    }

    this.parentMenu = this.$route.query.upper
    menuApi.getMenu(this.parentMenu).then((data) => {
      this.menuName = data.menuName;
      this.tabs = data.childMenus; //ce분과, it분과, 디스플레이분과 etc.
      this.currentTabMenu = this.tabs[this.tabIndex - 1];
    });

  },
  async mounted(){

    await userApi.get(this.$store.state.user.userid).then((data) =>{
        let checkGroup=[];
        let checkRole=false;
        /**
         * 43: 일반사용자, 44 : 관리자 , 3086: 회장단, 3101 : 위원장 , 3102 : 간사 , 13841: 삼성전자
         */
        
          for(let i = 0 ;i<data.userHasRoles.length;i++){
            if((data.userHasRoles[i].id.roleId=='44'|| data.userHasRoles[i].id.roleId=='3086'|| 
                data.userHasRoles[i].id.roleId=='3101'|| data.userHasRoles[i].id.roleId=='3102')){
                  checkRole=true; break;
                }
          }
                console.log(checkRole)

          // if(checkRole == false){

            if(data.departmentGroup){
              this.departmentItems = data.departmentGroup;
              checkGroup.push(1);
              groupApi.getGroup(1).then(data=>{
                console.log(data)
                this.rootGroups.push(data)
              })
            }
          
            if(data.clubGroup){
              this.clubItems = data.clubGroup;
              checkGroup.push(2);
              groupApi.getGroup(2).then(data=>{
                this.rootGroups.push(data)
              })
            }
            if(!checkGroup.length > 0){
              alert("분과 및 동호회 가입이 되어있지 않습니다.")
              this.$router.go(-1);
            }
          // }

          /**권한따라 모임분류 정보 표시 원할경우 위에 743행 및 765행도 주석 풀어야한다. if문 {}묶어야 작동  */
          // else{
          //   groupApi.flatList().then((data) => {
          //     this.rootGroups = data;
          //   });

          //   groupApi.flatList(1).then((data) => {
          //     this.departmentItems = data;
          //   });
          //   groupApi.flatList(2).then((data) => {
          //     this.clubItems = data;
          //   });
          // }
    })
  }
};
</script>

<style lang="scss" scoped>
@import "../../assets/style/layout/variables";

.scForm {
  width: 80%;
  margin: auto;
}

.h2Title {
  @include h2-title;
}

.formInfo {
  @include form-info;
}

.formInfo .formInfoTxt{
  width: 120px;
}
.userDd {
  padding: 10px 0;
}

.list {
  overflow: hidden;
}
// .mgr .col-sm-6 .col{
//   max-width: 100% !important;
// }

.formInfo .formInfoAreaAddr{
  width: 32rem !important;
}

.vBtnsc{
  margin-right: 0.5rem;
}
.btnStyleBx{display:flex;justify-content: flex-end;}
.formInfo .v-text-field input{min-width:100px !important;max-width:100px !important}
.infoCont .mb-meeting-info-date{display: flex;justify-content: flex-start;width:100%;}
.infoCont .mb-meeting-info-date .mgr.d-flex.col{align-items: center;}
.infoCont .mb-meeting-info-date .row-box{display: flex;justify-content: flex-start; }
@media screen and (max-width: 960px) {
  .formInfo .formInfoTxt{background: #fff;text-align: left;justify-content: flex-start;}
  .formInfo .infoCont{border-bottom: 1px solid #dfdfdf;}
  .formInfo .formInfoTxt{width:100%}
  .v-application .d-flex{padding:0}
  .v-application ul, .v-application ol{padding:0}
  .v-text-field input{border:1px solid #dfdfdf}
}
@media screen and (max-width: 640px) {
  .formInfo .infoCont{border:none}
  .v-input--selection-controls{margin:0;padding:0}
  .col{padding:0}
  .formInfo .formInfoTxt{padding:.5rem 1rem}
  .formInfo .formInfoArea{padding:0 1rem}
 .mb-meeting-info-addr{display: flex;flex-direction: row;flex-wrap: wrap;} 
 .mb-meeting-info-addr .v-input__slot{width:100px !important}
 .btn-top{display: none;}
 .formInfo .formInfoAreaAddr{width:100% !important;padding:0 1rem;box-sizing: border-box;}
 .btnStyleBx{padding:1rem;justify-content: center;}
 .formInfo{border: none;}
 .mb-meeting-info-date .v-input{width: 160px !important}
 .vue__time-picker{margin-left:.3rem}

 .col[data-v-2d0fc021]{flex-grow:inherit}
 
}
</style>